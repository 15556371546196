'use client';
import config from 'devextreme/core/config';

const LicenceCheckComponent = () => {
  if (typeof window !== 'undefined') {
    config({
      licenseKey:
        'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjljNjFkNzAwLTg2ZGMtMTFlNC04MGNjLTAwMTU1ZDYyNDcwMSIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQyCn0=.Aa0ih5v78W11/y/u2v8q8++xO6sMCy0bIoyiFPfYvHrzhMcopCRMSF9bqzq1jZ0ihprYIPP5lv/yr8j3e23Ffw+M1uIbF+/MYOTr9OeIx5mTiMtt7JYVntGmDvL+lYww3mISvw=='
    });
  }

  return null;
};

export default LicenceCheckComponent;
